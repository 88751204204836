<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Add new job offer</h2>
                            <div class="profile__content">
                                <p v-if="addJobForm.localError" class="custom-error mb-3">{{ addJobForm.localError }}</p>
                                <p v-if="getJobError" class="custom-error mb-3"> {{ getJobError }} </p>
                                <form :class="getDisabledJob ? 'disabled-element' : ''" @submit.prevent="addJob" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Job position*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobPosition" @input="$v.addJobForm.jobPosition.$touch()" :class="[{'input-error' : $v.addJobForm.jobPosition.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type job position" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobPosition.$error">
                                                    <p v-if="!$v.addJobForm.jobPosition.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobPosition.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company name*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobCompany" @input="$v.addJobForm.jobCompany.$touch()" :class="[{'input-error' : $v.addJobForm.jobCompany.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company name" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobCompany.$error">
                                                    <p v-if="!$v.addJobForm.jobCompany.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobCompany.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobContactEmail" @input="$v.addJobForm.jobContactEmail.$touch()" :class="[{'input-error' : $v.addJobForm.jobContactEmail.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company e-mail" type="email" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobContactEmail.$error">
                                                    <p v-if="!$v.addJobForm.jobContactEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.addJobForm.jobContactEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobCity" @input="$v.addJobForm.jobCity.$touch()" :class="[{'input-error' : $v.addJobForm.jobCity.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type city" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobCity.$error">
                                                    <p v-if="!$v.addJobForm.jobCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Form of employment*</label>
                                                <v-select :disabled="getDisabledJob" v-model.trim="addJobForm.jobType" @input="$v.addJobForm.jobType.$touch()" :class="[{'input-error' : $v.addJobForm.jobType.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="custom-sorter mb-1" labelTitle = "Select form of employment" :options="jobTypes" />
                                                <div v-if="$v.addJobForm.jobType.$error">
                                                    <p v-if="!$v.addJobForm.jobType.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobType.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobContactPhone" @input="$v.addJobForm.jobContactPhone.$touch()" :class="[{'input-error' : $v.addJobForm.jobContactPhone.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type contact phone number" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobContactPhone.$error">
                                                    <p v-if="!$v.addJobForm.jobContactPhone.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company website URL<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobContactUrl" @input="$v.addJobForm.jobContactUrl.$touch()" :class="[{'input-error' : $v.addJobForm.jobContactUrl.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company website URL" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobContactUrl.$error">
                                                    <p v-if="!$v.addJobForm.jobContactUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.addJobForm.jobContactUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledJob" v-model.trim="addJobForm.jobCountry" @input="$v.addJobForm.jobCountry.$touch()" :class="[{'input-error' : $v.addJobForm.jobCountry.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.addJobForm.jobCountry.$error">
                                                    <p v-if="!$v.addJobForm.jobCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Application method*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Determine how candidates can apply for this position.</span></label>
                                                <div>
                                                    <span @click="resetApplyMethod"><p-radio class="pretty p-default p-curve p-smooth" name="radio" color="success" v-model="addJobForm.applyMethod" value="email">E-mail</p-radio></span>
                                                    <span @click="resetApplyMethod"><p-radio class="pretty p-default p-curve p-smooth" name="radio" color="success" v-model="addJobForm.applyMethod" value="url">URL</p-radio></span>
                                                    <div v-if="$v.addJobForm.applyMethod.$error">
                                                        <p v-if="!$v.addJobForm.applyMethod.required" class="custom-error">This field is required</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col v-if="addJobForm.applyMethod == 'email'" cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Apply e-mail address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />e-mail address that candidate can use to apply for this position.</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobApply" @input="$v.addJobForm.jobApply.$touch()" :class="[{'input-error' : $v.addJobForm.jobApply.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type apply e-mail address" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobApply.$error">
                                                    <p v-if="!$v.addJobForm.jobApply.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobApply.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                                <p v-if="invalidEmail" class="custom-error">E-mail address is invalid</p>
                                            </div>
                                        </b-col>
                                        <b-col v-if="addJobForm.applyMethod == 'url'" cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Apply URL*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Link URL to form or website where candidate can apply for this position. With http or https protocol</span></label>
                                                <input :disabled="getDisabledJob" v-model.trim="addJobForm.jobApply" @input="$v.addJobForm.jobApply.$touch()" :class="[{'input-error' : $v.addJobForm.jobApply.$error }, getDisabledJob ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type url" autocomplete="off" />
                                                <div v-if="$v.addJobForm.jobApply.$error">
                                                    <p v-if="!$v.addJobForm.jobApply.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobApply.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                                <p v-if="invalidUrl" class="custom-error">This field must start with either http:// or https://</p>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Job description*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="addJobForm.jobDescription" @input="$v.addJobForm.jobDescription.$touch()" :class="[{'input-error' : $v.addJobForm.jobDescription.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job description"></vue-editor>
                                                <div v-if="$v.addJobForm.jobDescription.$error">
                                                    <p v-if="!$v.addJobForm.jobDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Qualifications and skills*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="addJobForm.jobQualifications" @blur="$v.addJobForm.jobQualifications.$touch()" :class="[{'input-error' : $v.addJobForm.jobQualifications.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job qualifications and skills"></vue-editor>
                                                <div v-if="$v.addJobForm.jobQualifications.$error">
                                                    <p v-if="!$v.addJobForm.jobQualifications.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobQualifications.editorCustomValidator" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobQualifications.maxLength" class="custom-error">This field must not exceed 2000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Offer and benefit*</label>
                                                <vue-editor :disabled="getDisabledJob" v-model.trim="addJobForm.jobOffer" @blur="$v.addJobForm.jobOffer.$touch()" :class="[{'input-error' : $v.addJobForm.jobOffer.$error }, getDisabledJob ? 'disabled-element' : '' ]" :editorToolbar="customToolbar" placeholder="Type job offer and benefits"></vue-editor>
                                                <div v-if="$v.addJobForm.jobOffer.$error">
                                                    <p v-if="!$v.addJobForm.jobOffer.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobOffer.editorCustomValidator" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.addJobForm.jobOffer.maxLength" class="custom-error">This field must not exceed 2000 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledJob" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Save job offer</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import jobFormTypes from '../assets/files/jobFormTypes.js'
import { mapGetters } from 'vuex'
import PrettyRadio from 'pretty-checkbox-vue/radio'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'

function editorCustomValidator (value) {
  return value !== '<ul><li><br></li></ul>'
}

function urlCustomValidator (value) {
  if (value !== '') {
    var pattern = /^((http|https):\/\/)/

    if (!pattern.test(value)) {
      return false
    }
  }
  return true
}

export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    VSelect,
    VueEditor,
    'p-radio': PrettyRadio
  },
  data: function () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      jobTypes: [],
      countries: [],
      addJobForm: {
        jobPosition: null,
        jobType: null,
        jobCompany: null,
        jobContactEmail: '',
        jobContactPhone: '',
        jobContactUrl: '',
        jobCity: null,
        jobCountry: null,
        jobApply: null,
        jobDescription: null,
        jobQualifications: '<ul></ul>',
        jobOffer: '<ul></ul>',
        applyMethod: null,
        localError: null
      },
      invalidEmail: false,
      invalidUrl: false
    }
  },

  validations: {
    addJobForm: {
      jobPosition: {
        required: required,
        maxLength: maxLength(200)
      },
      jobType: {
        required: required,
        maxLength: maxLength(200)
      },
      jobCompany: {
        required: required,
        maxLength: maxLength(200)
      },
      jobContactEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      jobContactPhone: {
        maxLength: maxLength(20)
      },
      jobContactUrl: {
        maxLength: maxLength(200),
        urlCustomValidator
      },
      jobCity: {
        required: required,
        maxLength: maxLength(200)
      },
      jobCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      jobApply: {
        required: required,
        maxLength: maxLength(200)
      },
      jobDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      jobQualifications: {
        required: required,
        maxLength: maxLength(2000),
        editorCustomValidator
      },
      jobOffer: {
        required: required,
        maxLength: maxLength(2000),
        editorCustomValidator
      },
      applyMethod: {
        required: required
      }
    }
  },
  methods: {
    addJob () {
      if (this.addJobForm.applyMethod == 'email') {
        if (this.validateEmail(this.addJobForm.jobApply)) {
          this.invalidEmail = false

          this.$store.dispatch('setDisabledJob')
          this.$v.addJobForm.$touch()
          if (!this.$v.addJobForm.$invalid) {
            this.$store.dispatch('addJob', { position: this.addJobForm.jobPosition, type: this.addJobForm.jobType, company: this.addJobForm.jobCompany, email: this.addJobForm.jobContactEmail, phone: this.addJobForm.jobContactPhone, website: this.addJobForm.jobContactUrl, city: this.addJobForm.jobCity, country: this.addJobForm.jobCountry, apply: this.addJobForm.jobApply, desc: this.addJobForm.jobDescription, qual: this.addJobForm.jobQualifications, offer: this.addJobForm.jobOffer, method: this.addJobForm.applyMethod })
              .then(() => {
                this.addJobForm.localError = ''
                this.scrollToTop(500)
              })
              .catch(error => {
                this.addJobForm.localError = error.message
                this.$store.dispatch('clearDisabledJob')
              })
          } else {
            this.scrollToTop(500)
            this.addJobForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledJob')
          }
        } else {
          this.invalidEmail = true
          this.$v.addJobForm.$touch()
          this.scrollToTop(500)
          this.addJobForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledJob')
        }
      } else {
        if (this.validateUrl(this.addJobForm.jobApply)) {
          this.invalidUrl = false

          this.$store.dispatch('setDisabledJob')
          this.$v.addJobForm.$touch()
          if (!this.$v.addJobForm.$invalid) {
            this.$store.dispatch('addJob', { position: this.addJobForm.jobPosition, type: this.addJobForm.jobType, company: this.addJobForm.jobCompany, email: this.addJobForm.jobContactEmail, phone: this.addJobForm.jobContactPhone, website: this.addJobForm.jobContactUrl, city: this.addJobForm.jobCity, country: this.addJobForm.jobCountry, apply: this.addJobForm.jobApply, desc: this.addJobForm.jobDescription, qual: this.addJobForm.jobQualifications, offer: this.addJobForm.jobOffer, method: this.addJobForm.applyMethod })
              .then(() => {
                this.addJobForm.localError = ''
                this.scrollToTop(500)
              })
              .catch(error => {
                this.addJobForm.localError = error.message
                this.$store.dispatch('clearDisabledJob')
              })
          } else {
            this.scrollToTop(500)
            this.addJobForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledJob')
          }
        } else {
          this.invalidUrl = true
          this.$v.addJobForm.$touch()
          this.scrollToTop(500)
          this.addJobForm.localError = 'Invalid form'
          this.$store.dispatch('clearDisabledJob')
        }
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    validateEmail (email) {
      var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validateUrl (url) {
      var pattern = /^((http|https):\/\/)/

      if (!pattern.test(url)) {
        return false
      } else {
        return true
      }
    },
    resetApplyMethod () {
      this.addJobForm.jobApply = ''
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledJob')
  },
  created () {
    this.countries = countriesList
    this.jobTypes = jobFormTypes
  },
  computed: {
    ...mapGetters([
      'getJobError',
      'getDisabledJob'
    ])
  },
  watch: {
    'addJobForm.jobApply': function () {
      if (this.addJobForm.jobApply != '') {
        this.invalidEmail = false
        this.invalidUrl = false
      }
    }
  }
}
</script>
